@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	transition: all 1s cubic-bezier(0.78, 0.01, 0.15, 0.99);
	font-family: Manrope, sans-serif !important;
}

.font-transition,
.font-transition * {
	transition: color 1s ease-in-out;
}

body,
html {
	overflow: hidden;
}

@layer base {
	html {
		font-family: Manrope, system-ui, sans-serif;
	}
}

